<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl w-full">

      <h2 class="text-2xl font-medium p-4 border-b-2 border-red-600">
        Performance Appraisal and Bonus Distribution
      </h2>

      <img
        src="../../assets/img/performance_appraisal_banner.jpg"
        alt="Performance Appraisal and Bonus Distribution Banner"
        class="object-cover w-full lg:h-96 h-80 mt-4"
      >

      <div class="p-4">
        <p class="py-4">
          Performance bonus is a common practice adopted by foreign and local
          companies to reward employees for achieving performance targets.
          However, outstanding performers do not necessarily earn bonus commensurate
          with their achievements, since bonus pool is also tightly associated with
          company’s profitability. In some companies, where company target is missed,
          no performance bonus is paid regardless of how well individuals perform.
        </p>
        <p class="pb-4">
          MRC works together with you to review and design your performance bonus plan
          to reinforce the motivation of your employees in achieving financial targets
          and boosting company performance.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
